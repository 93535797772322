import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/testimonial.css"

export default function TestimonialsPage(props) {
  return (
    <Layout>
      <Seo title="Testimonials" />
      <div class="contentBox">
        <h1>Testimonials</h1>
        <div className="testimonials-container">
          <div className="testimonial">
            <p>
              Our daughter has lived in a Mars Group home since August of 2011.
              She has severe autism and had to leave our home in a crisis
              situation. It was always our plan to transition her into a group
              home at the age of 25 but the decision for us to do so came
              earlier than expected. She was almost 22 when we had to make the
              decision to move forward with her new living arrangements. We were
              introduced to the Mars Group and are so thankful we were. They
              were exceptional in making the transition as smooth as possible
              for us. We brought anything that was needed, including her swing
              set, to make her new home hers. That gave us the comfort we needed
              at the time.
            </p>
            <p>
              Our daughter has very little speech and language and the staff has
              been very good over the years at getting to know her wants and
              needs. They give her the care she needs in a professional way but
              at the same time with a very caring and personal touch. They keep
              her, as well as all the clients, engaged and active on a daily
              basis. We call the home every day to speak with her and the staff.
              We also visit her often and take her out to dinner. We now feel
              comfortable going on vacations for extended periods of time
              knowing that she is being well taken care of.
            </p>
            <p>
              Thank you Mars Group,
              <br />
              <p>Dave and Theresa Baxter</p>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
